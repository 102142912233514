<template>
  <div class="login" :style="loginContainerStyle">
    <!-- <vue-particles color="#dedede"></vue-particles> -->
    <div class="centent-box pCenter">
      <div class="loginModal">
        <h1 class="fc255 t_a mt20">
          <i class="iconfont icon-kaoshi_moren2x logoIcon"></i>
          통통에듀 관리자
        </h1>
        <div class="loginDiv flexCC mt20">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="adminForm"
            :inline="true"
          >
            <el-form-item prop="username">
              <el-input
                @keyup.enter.native="submitAdmin('adminForm')"
                :placeholder="$t('inputAccount')"
                prefix-icon="el-icon-user"
                v-model="ruleForm.username"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
              @keyup.enter.native="submitAdmin('adminForm')"
                :placeholder="$t('inputPassword')"
                prefix-icon="el-icon-lock"
                v-model="ruleForm.password"
                show-password
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <div
                class="btn"
                :loading="isShow"
                @click="submitAdmin('adminForm')"
              >
                <i class="iconfont icon-xiayibu loginBut"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/index";
import bgImg from '@/assets/img/login.png'
import { mapMutations } from "vuex";
export default {
  name: "login",
  data() {
    return {
      isShow: false,
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: this.$t('inputAccount'),
            trigger: "blur",
          },
          { min: 3, message: this.$t('min3'), trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: this.$t('inputPassword'),
            trigger: "blur",
          },
          { min: 6, message: this.$t('min6'), trigger: "blur" },
        ],
      },
    };
  },
  components: {},
  computed: {
      loginContainerStyle () {
      return {
        backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: '50%'
      }
    }
  },
  methods: {
    ...mapMutations(["saveUserInfo"]),
    async submitAdmin(formName) {
      await this.$refs[formName].validate();
      this.loginHandle(this.ruleForm);
    },
    async loginHandle(ruleForm) {
      this.isShow = true;
      try {
        let res = await login(ruleForm);
        if (res.code) return;
        this.saveUserInfo(res);
        this.$router.push("/");
        this.$message.success({
          message: this.$t('loginSuccess'),
        });
        this.isShow = false;
      } catch (e) {
        this.isShow = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.centent-box {
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.5);
  .loginModal {
    height: 100%;
    flex-direction: column;
    .logoIcon{
        font-size: 40px;
        margin-right:20px;
        align-items: center;
    }
    h1{
        font-size: 40px;
    }
    .loginDiv{
      .btn{
        padding:0;
        line-height:1;
      }
        .loginBut{
            color:#fff;
            font-size: 40px;
        }
    }
  }
}
.login {
  width: 100%;
  height: 100%;
}
</style>